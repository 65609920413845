import React from "react";
import { motion, HTMLMotionProps } from "framer-motion";
import styled from "styled-components";
import {
    space,
    color,
    typography,
    layout,
    flexbox,
    grid,
    background,
    border,
    position,
    shadow,
    compose,
    borderRadius
} from "styled-system";

import { BaseBoxProps, specialConfig } from "../";

const styleProps = compose(
    space,
    color,
    typography,
    layout,
    flexbox,
    grid,
    background,
    border,
    position,
    shadow,
    borderRadius,
    specialConfig
);

/** Need to remove it from here in order to use the framer motion transition */
type BaseBoxPropsNoTransition = Omit<BaseBoxProps, "transition">;

export const styledMotion = (component: keyof JSX.IntrinsicElements | React.ComponentType<any>) => {
    return styled(component)<BaseBoxPropsNoTransition>`
        ${styleProps}
    `;
};

export type MotionDivProps = HTMLMotionProps<"div"> & BaseBoxPropsNoTransition;

export const MotionDiv = styledMotion(motion.div);
export const MotionSection = styledMotion(motion.section);
