import React from "react";
import { useController } from "react-hook-form";

import { Box, FormLabel, RHCommonProps } from "Atoms";
import { Languagekey, useLanguage } from "Providers/languageProvider/LanguageProvider";
import { formatDateToLocal, formatFinancialNumbers } from "Utils";
import { useQoplaStore } from "Stores";

export type RHFormLabelAndValueProps = {
    languageKey: Languagekey;
    isDate?: boolean;
    extraText?: string;
} & Omit<RHCommonProps, "formLabel">;

/**
 * [RHF] - [LABEL AND VALUE]
 * - Ability to have a form label with a value from the form for display purpose
 *  * Uses Language Key for translation
 * @example Email: xxx@qopla.com
 */
export const RHFormLabelAndValue: React.FC<RHFormLabelAndValueProps> = ({
    name,
    control,
    languageKey,
    wrapperProps,
    isDate = false,
    extraText = ""
}) => {
    const {
        field: { value }
    } = useController({ name, control });

    const { translate } = useLanguage();
    const { companyLocale } = useQoplaStore();

    const valueIsPresent = !!value;

    let convertedValue = value;

    if (isDate && valueIsPresent) {
        convertedValue = formatDateToLocal(value, "YYYY-MM-DD");
    } else if (name === "discountAmount") {
        convertedValue = formatFinancialNumbers(value, companyLocale);
    }

    return (
        <Box display="flex" alignItems="center" flexDirection="row" mb={3} {...wrapperProps}>
            <FormLabel>{`${translate(languageKey)}: `}</FormLabel>
            <FormLabel fontWeight="normal">{!!convertedValue ? `${convertedValue} ${extraText}` : "-"}</FormLabel>
        </Box>
    );
};
