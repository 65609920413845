import css from "@styled-system/css";
import styled from "styled-components";

import { Box, BaseBoxProps } from "../Box/Box";
import { tx } from "../PseudoBox/PseudoBox";

interface IInputBox {
    parentId: string;
    type: "checkbox" | "radio";
    _hover: BaseBoxProps;
    _disabled: BaseBoxProps;
    _invalid: BaseBoxProps;
    _focus: BaseBoxProps;
    _checked: BaseBoxProps;
    _child: BaseBoxProps;
    _checkedAndChild: BaseBoxProps;
    _checkedAndDisabled: BaseBoxProps;
    _checkedAndFocus: BaseBoxProps;
    _checkedAndHover: BaseBoxProps;
    _backgroundColor: string;
}

export type InputBoxProps = Partial<IInputBox> & BaseBoxProps;

export const InputBox = styled(Box)<InputBoxProps>(
    ({
        _backgroundColor,
        type = "checkbox",
        parentId,
        _hover,
        _disabled,
        _invalid,
        _focus,
        _checked,
        _child = { opacity: 0 },
        _checkedAndChild = { opacity: 1 },
        _checkedAndDisabled,
        _checkedAndFocus,
        _checkedAndHover
    }) => {
        const checkedAndDisabled = `input[type=${type}]:checked:disabled + &, input[type=${type}][aria-checked=mixed]:disabled + &`;
        const checkedAndHover = `input[type=${type}]:checked:hover:not(:disabled) + &, input[type=${type}][aria-checked=mixed]:hover:not(:disabled) + &`;
        const checkedAndFocus = `input[type=${type}]:checked:focus + &, input[type=${type}][aria-checked=mixed]:focus + &`;
        const disabled = `input[type=${type}]:disabled + & `;
        const focus = `input[type=${type}]:focus + & `;
        const hover = `input[type=${type}]:hover:not(:disabled):not(:checked) + &`;
        const checked = `input[type=${type}]:checked + &, input[type=${type}][aria-checked=mixed] + &`;
        const invalid = `input[type=${type}][aria-invalid=true] + &`;

        return css({
            backgroundColor: _backgroundColor,
            [focus]: tx(_focus),
            [hover]: tx(_hover),
            [disabled]: tx(_disabled),
            [invalid]: tx(_invalid),
            [checkedAndDisabled]: tx(_checkedAndDisabled),
            [checkedAndFocus]: tx(_checkedAndFocus),
            [checkedAndHover]: tx(_checkedAndHover),
            "& > *": tx(_child),
            [checked]: {
                ...tx(_checked),
                "& > *": tx(_checkedAndChild)
            }
        });
    }
);
